import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Position = (props) => {
  return (
    <Form.Group>
      <Form.Group>
        <Row>
          <Col xs={6}>
            <Form.Label className="not-required">Position
            </Form.Label>
          </Col>
          <Col xs={6}>
            <Form.Control
              onChange={(e) => {
                const { value } = e.target; // <-- moved outside asynchronous context to fix errors

                props.handlePositionChange(value);
              }}
              type="text"
              placeholder="Your position..."
              maxLength="25"
            />
          </Col>
        </Row>
      </Form.Group>
    </Form.Group>
  );
};

export default Position;
