import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classNames from 'classnames'

const Email = (props) => {
  return (
    <Form.Group>
      <Form.Group>
        <Row>
          <Col xs={6}>
            <Form.Label>
              <span className="required">*</span>Email
            </Form.Label>
          </Col>
          <Col xs={6}>
            <Form.Control
              onChange={(e) => {
                const { value } = e.target; // <-- moved outside asynchronous context to fix errors

                props.handleEmailChange(value);
              }}
              type="email"
              placeholder="Your email..."
              maxLength="50"
              required
            />
             <Form.Text className="text-info">
              If you have a company email, please provide for class credit.
            </Form.Text>
            <Form.Text className="text-muted">
              We'll only use your email for class registration and for a
              confirmation email.
            </Form.Text>
          </Col>
        </Row>
      </Form.Group>
    </Form.Group>
  );
};

export default Email;
