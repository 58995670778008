import React, { useState, useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { GetAssets } from '../Services/mendixApiCalls'

import '../Styles/index.css'

function Footer() {
    const [bakkenEmail, setBakkenEmail] = useState('Email Address unknown for Bakken')
    const [centralRockiesEmail, setCentralRockiesEmail] = useState('Email Address unknown for Central Rockies')
    const [eagleFordEmail, setEagleFordEmail] = useState('Email Address unknown for Eagle Ford')
    const [permianEmail, setPermianEmail] = useState('Email Address unknown for Permian')

    useEffect(() => {
        GetAssets('L48')
        .then( 
          (result) => {
            result.forEach(asset => {
                switch(asset.Name) {
                    case "Bakken":
                        setBakkenEmail(asset.EmailAddress)
                        break
                    case "Central Rockies":
                        setCentralRockiesEmail(asset.EmailAddress)
                        break
                    case "Eagle Ford":
                        setEagleFordEmail(asset.EmailAddress)
                        break
                    case "Permian":
                        setPermianEmail(asset.EmailAddress)
                        break
                    default:                        
                }
            });
          },
          (error) => {
            //setBakkenEmail(error.message)
            //setCentralRockiesEmail(error.message)
            //setEagleFordEmail(error.message)
            //setPermianEmail(error.message)
          }
        )
    }, [])

    return (
        <div id="footer" style={{width: "100%", position: "fixed", bottom: 0, backgroundColor: "#f8f9fa"}}>
            <div>
                <p style={{marginTop: "5px", marginLeft: "16px"}}>For any questions, send an email to the appropriate address below.</p>
            </div>

            <div>
                <Navbar collapseOnSelect expand="xxl" bg="light" variant="light">
                    <Nav className="mr-auto">
                        <Nav.Item><b>Bakken</b> - <br /> {bakkenEmail}</Nav.Item>
                    </Nav>
                    <Nav className="mr-auto">
                        <Nav.Item><b>Central Rockies</b> - <br /> {centralRockiesEmail}</Nav.Item>
                    </Nav>
                    <Nav className="mr-auto">
                        <Nav.Item><b>Eagle Ford</b> - <br /> {eagleFordEmail}</Nav.Item>
                    </Nav>
                    <Nav className="mr-auto">
                        <Nav.Item><b>Permian</b> - <br /> {permianEmail}</Nav.Item>
                    </Nav>
                </Navbar>
            </div>
        </div>
    )
}

export default Footer