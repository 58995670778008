import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function TakeSupervisorModuleQuestion({
  handleWillTakeSupervisorModuleChange,
}) {
  function handleClick(e) {
    if (e.target.id === "yes") {
      handleWillTakeSupervisorModuleChange(true);
    } else {
      handleWillTakeSupervisorModuleChange(false);
    }
  }

  return (
    <>
      <Form.Group>
        <Row>
          <Col xs={6}>
            <Form.Label inline="true">
              Will you be participating in the supervisor modules?
            </Form.Label>
          </Col>
          <Col xs={6}>
            <Form.Check
              onClick={handleClick}
              inline="true"
              label="yes"
              type="radio"
              name="supervisorRadios"
              id="yes"
            />
            <Form.Check
              onClick={handleClick}
              inline="true"
              label="no"
              type="radio"
              name="supervisorRadios"
              id="no"
            />
          </Col>
        </Row>
      </Form.Group>
    </>
  );
}

export default TakeSupervisorModuleQuestion;
