import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { GetDepartments } from "../../Services/mendixApiCalls";

function ChooseDepartment({
  handleDepartmentChange,
  department,
  businessUnit,
}) {
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    handleDepartmentChange("");

    GetDepartments(businessUnit).then(
      (result) => {
        result.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        setDepartments(result);
      },
      (error) => {
        setDepartments(error.message);
      }
    );
  }, [handleDepartmentChange, businessUnit]);

  const changeDepartmentSelection = (selectedDepartment) => {
    handleDepartmentChange(selectedDepartment);
  };

  function Departments() {
    const hasDepartments =
      departments !== "Failed to fetch." && departments.length > 0;

    const NoDepartmentsFound = () => {
      return <Dropdown.Item href="#">No departments found</Dropdown.Item>;
    };

    const DepartmentItems = () => {
      return (
        <>
          {departments.map((department) => (
            <Dropdown.Item
              onClick={() => changeDepartmentSelection(department.Name)}
              key={department.Name}
              href="#"
            >
              {department.Name}
            </Dropdown.Item>
          ))}
        </>
      );
    };

    return (
      <DropdownButton
        variant={hasDepartments ? "outline-primary" : "outline-warning"}
        id="department-dropdown"
        title={department}
      >
        {hasDepartments ? <DepartmentItems /> : <NoDepartmentsFound />}
      </DropdownButton>
    );
  }

  return (
    <Form.Group>
      <Row style={{ marginTop: "15px" }}>
        <Col xs={6}>
          <Form.Label className="not-required">Department
          </Form.Label>
        </Col>
        <Col xs={6}>
          <Departments />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default ChooseDepartment;
