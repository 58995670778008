import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { GetFieldLocations } from "../../Services/mendixApiCalls";

function ChooseFieldLocation({
  asset,
  fieldLocation,
  handleFieldLocationChange,
}) {
  const [fieldLocations, setFieldLocations] = useState([]);

  useEffect(() => {
    handleFieldLocationChange("");

    GetFieldLocations(asset).then(
      (result) => {
        result.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        setFieldLocations(result);
      },
      (error) => {
        setFieldLocations(error.message);
      }
    );
  }, [asset, handleFieldLocationChange]);

  const changeFieldLocationSelection = (selectedFieldLocation) => {
    handleFieldLocationChange(selectedFieldLocation);
  };

  function FieldLocations() {
    const hasFieldLocations =
      fieldLocations !== "Failed to fetch." && fieldLocations.length > 0;

    const NoLocationsFound = () => {
      return <Dropdown.Item href="#">No locations found</Dropdown.Item>;
    };

    const FieldLocationItems = () => {
      return (
        <>
          {fieldLocations.map((fieldLocation) => (
            <Dropdown.Item
              onClick={() => changeFieldLocationSelection(fieldLocation.Name)}
              key={fieldLocation.Name}
              href="#"
            >
              {fieldLocation.Name}
            </Dropdown.Item>
          ))}
        </>
      );
    };

    return (
      <DropdownButton
        variant={hasFieldLocations ? "outline-primary" : "outline-warning"}
        id="fieldlocation-dropdown"
        title={fieldLocation}
      >
        {hasFieldLocations ? <FieldLocationItems /> : <NoLocationsFound />}
      </DropdownButton>
    );
  }

  return (
    <>
      <Form.Group>
        <Row>
          <Col xs={6}>
            <Form.Label>
              {" "}
              <span className="required">*</span>Field Location
            </Form.Label>
          </Col>
          <Col xs={6}>
            <FieldLocations />
          </Col>
        </Row>
      </Form.Group>
    </>
  );
}

export default ChooseFieldLocation;
