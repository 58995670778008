import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const SupvEmailL48 = (props) => {
  return (
    <Form.Group>
      
      <Form.Group>
        <Row>
          <Col xs={6}>
            <Form.Label>
              <span className="required">*</span>Supervisor Email
            </Form.Label>
          </Col>
          <Col xs={6}>
            <Form.Control
              onChange={(e) => {
                const { value } = e.target; // <-- moved outside asynchronous context to fix errors

                props.handleSupervisorEmailL48Change(value);
              }}
              type="email"
              placeholder="Supervisor's email..."
              maxLength="50"
              required
            />
            <Form.Text className="text-muted">
              Used for verification when registering for a class.
            </Form.Text>
          </Col>
        </Row>
      </Form.Group>
     </Form.Group>
  );
};

export default SupvEmailL48;
