import React, { useState, useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { GetAssets } from '../Services/mendixApiCalls'

import '../Styles/index.css'

function FooterAlaska() {
    const [anchorageEmail, setAnchorageEmail] = useState('Email Address unknown for Anchorage')
    const [alpineEmail, setAlpineEmail] = useState('Email Address unknown for Alpine')
    const [kuparukEmail, setKuparukEmail] = useState('Email Address unknown for Kuparuk')
    const [aviationEmail, setAviationEmail] = useState('Email Address unknown for Aviation')
    const [willowEmail, setWillowEmail] = useState('Email Address unknown for Willow')

    useEffect(() => {
        GetAssets('Alaska')
        .then( 
          (result) => {
            result.forEach(asset => {
                switch(asset.Name) {
                    case "Anchorage":
                        setAnchorageEmail(asset.EmailAddress)
                        break
                    case "Alpine":
                        setAlpineEmail(asset.EmailAddress)
                        break
                    case "Kuparuk":
                        setKuparukEmail(asset.EmailAddress)
                        break
                    case "Aviation":
                        setAviationEmail(asset.EmailAddress)
                        break
                    case "Willow":
                        setWillowEmail(asset.EmailAddress)
                        break
                    default:                        
                }
            });
          },
          (error) => {
            /*setAnchorageEmail(error.message)
            setAlpineEmail(error.message)
            setKuparukEmail(error.message)
            setAviationEmail(error.message)
            setWillowEmail(error.message)*/
          }
        )
    }, [])

    return (
        <div id="footer" style={{width: "100%", position: "fixed", bottom: 0, backgroundColor: "#f8f9fa"}}>
            <div>
                <p style={{marginTop: "5px", marginLeft: "16px"}}>For any questions, send an email to the appropriate address below.</p>
            </div>

            <div>
                <Navbar collapseOnSelect expand="xxl" bg="light" variant="light">
                    <Nav className="mr-auto">
                        <Nav.Item><b>Anchorage</b> - <br /> {anchorageEmail}</Nav.Item>
                    </Nav>
                    <Nav className="mr-auto">
                        <Nav.Item><b>Alpine</b> - <br /> {alpineEmail}</Nav.Item>
                    </Nav>
                    <Nav className="mr-auto">
                        <Nav.Item><b>Kuparuk</b> - <br /> {kuparukEmail}</Nav.Item>
                    </Nav>
                    <Nav className="mr-auto">
                        <Nav.Item><b>Aviation</b> - <br /> {aviationEmail}</Nav.Item>
                    </Nav>
                    <Nav className="mr-auto">
                        <Nav.Item><b>Willow</b> - <br /> {willowEmail}</Nav.Item>
                    </Nav>
                </Navbar>
            </div>
        </div>
    )
}

export default FooterAlaska