import React, { useState } from "react";
import Form from "react-bootstrap/Form";

import FormHeader from "../Components/RegistrationFormComponents/FormHeader";
import ChooseDepartment from "../Components/RegistrationFormComponents/ChooseDepartmentAlaska";
import ChooseAsset from "../Components/RegistrationFormComponents/ChooseAsset";
import ChooseCompany from "../Components/RegistrationFormComponents/ChooseCompany";
import Name from "../Components/RegistrationFormComponents/Name";
import SupvEmailAlaska from "../Components/RegistrationFormComponents/SupvEmailAlaska";
import Email from "../Components/RegistrationFormComponents/EmailAlaska";
import Phone from "../Components/RegistrationFormComponents/PhoneAlaska";
import Position from "../Components/RegistrationFormComponents/PositionAlaska";
import BBSGroupNumberField from "../Components/RegistrationFormComponents/BBSGroupNumberField";
import ChooseClass from "../Components/RegistrationFormComponents/ChooseClass";
import SubmitButtonAndModal from "../Components/RegistrationFormComponents/SubmitButtonAndModal";
import Footer from "../Components/FooterAlaska";

import {
  isNotEmpty,
  isValidName,
  isValidEmail,
  isValidPhoneNumber,
} from "../Services/formHelperFunctions";

import "../Styles/RegistrationForm.css";

function RegistrationForm() {
  const BUSINESSUNIT = "Alaska";
  const CLASSNAME = "Workshops";
  const CLASSABBREVIATION = "";

  const [department, setDepartment] = useState("");
  const [asset, setAsset] = useState("");
  const [company, setCompany] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleInitial, setMiddleInitial] = useState("");
  const [lastName, setLastName] = useState("");
  const [supervisorEmail, setSupervisorEmail] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [position, setPosition] = useState("");
  const [groupNumber, setGroupNumber] = useState("");

  const [classChoice, setClassChoice] = useState("");
  const [randomGuid, setRandomGuid] = useState("");

  const registrationData = {
    FirstName: firstName,
    MiddleInitial: middleInitial,
    LastName: lastName,
    SupervisorEmail: supervisorEmail,
    Email: email,
    PhoneNumber: phoneNumber,
    Position: position,
    BBSGroupNumber: groupNumber,

    Department: { Name: department + "~" + BUSINESSUNIT },
    Asset: { Name: asset },
    Company: { Name: company + "~" + asset },
    Classes: { RandomGuid: randomGuid },
    Tenant: { BusinessUnit: BUSINESSUNIT },
  };

  function isValidForm() {
    if (
      isNotEmpty(asset) &&
      isValidName(firstName) &&
      isValidName(lastName) &&
      isValidEmail(email) &&
      isNotEmpty(randomGuid) &&
      isNotEmpty(company)
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <div id="body">
        <Form className="registration-form">
          <FormHeader
            formName={BUSINESSUNIT + " " + CLASSNAME}
            businessUnit={BUSINESSUNIT}
          />

          <Form.Group>
            <Form.Label style={{ fontWeight: "bold", marginTop: "15px" }}>
              Please answer the following questions so we can assist you in
              locating the appropriate {CLASSABBREVIATION} class.
            </Form.Label>
            <Form.Group>
             
              <ChooseAsset
                businessUnit={BUSINESSUNIT}
                asset={asset}
                handleAssetChange={setAsset}
              />
              <ChooseClass
                      selectedAsset={asset}
                      selectedClass={classChoice}
                      handleClassChange={setClassChoice}
                      handleRandomGuidChange={setRandomGuid}
              />
            </Form.Group>
            <br />           
            <Form.Group>
              {isNotEmpty(asset) &&
                isNotEmpty(classChoice) && (
                  <Form.Group>
                    <Name
                     handleFirstNameChange={setFirstName}
                     handleMiddleInitialChange={setMiddleInitial}
                     handleLastNameChange={setLastName}
                    />  
                     <ChooseCompany
                      selectedAsset={asset}
                      company={company}
                      handleCompanyChange={setCompany}
                    />
                     <ChooseDepartment
                      department={department}
                      handleDepartmentChange={setDepartment}
                      businessUnit={BUSINESSUNIT}
                                    />
                    <Email                                           
                      handleEmailChange={setEmail}
                    />
                    <Phone                                           
                      handlePhoneNumberChange={setPhoneNumber}
                    />
                     <BBSGroupNumberField
                      handleGroupNumberChange={setGroupNumber}
                    />
                    <Position                                           
                      handlePositionChange={setPosition}
                    />
                    <SupvEmailAlaska
                      handleSupervisorEmailAlaskaChange={setSupervisorEmail}
                    />                  
                  </Form.Group>
                )}
            </Form.Group>
            <br />
            <fieldset>
            <Form.Group >

              {isValidForm() && (                
                <SubmitButtonAndModal 
                  registrationData={registrationData}
                  classChoice={classChoice}
                />
              )}
            </Form.Group>
            </fieldset>
          </Form.Group>
        </Form>
      </div>

      <Footer />
    </>
  );
}

export default RegistrationForm;
