import React from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import Announcement from "./Announcement";

export default function FormHeader({ formName, businessUnit }) {
  return (
    <>
      <Form.Group>
        <h3 style={{ marginBottom: "15px" }}>
          <span style={{ color: "darkblue" }}>{" " + formName + " "}</span>
          <span> | </span>
          <span style={{ color: "red" }}> Class Registration </span>
        </h3>
        <br />
        <Link to="/">
          <Button variant="outline-secondary">Back</Button>
        </Link>
        <br /> <br />
        <Announcement businessUnit={businessUnit} />
      </Form.Group>
      <hr /> <br />
    </>
  );
}
