const apiUrl = "rest/SLSService/v1";

function getBaseUrl() {
  if (window.location.host === "externalcopregister-d.cop.cloudcopapps.net") {
    return "https://copsls-a.copapp.net";
  } else if (window.location.host === "externalcopregister-t.cop.cloudcopapps.net") {
    return "https://copsls-a.copapp.net";
  } else if (window.location.host === "externalcopregister.cop.cloudcopapps.net") {
    return "https://copsls.copapp.net";
  } else {
    return "http://localhost:8080";
  }
}

function isNotEmptyOrUndefined(someString) {
  return someString !== undefined && someString !== "";
}

export async function GetAnnouncement(businessUnit) {
  const response = await fetch(
    encodeURI(
      `${getBaseUrl()}/${apiUrl}/announcement?businessunit=${businessUnit}`
    ),
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  );

  return response.json();
}

export async function GetAvailableClasses(asset, fieldLocation) {
  let fieldLocationEncoded = null;

  isNotEmptyOrUndefined(fieldLocation)? fieldLocationEncoded = new Buffer.from(fieldLocation).toString("base64") : fieldLocationEncoded = undefined;

  const queryString =
    "?" +
    (isNotEmptyOrUndefined(fieldLocationEncoded)
      ? `FieldLocation=${fieldLocationEncoded}&`
      : "") +
    (isNotEmptyOrUndefined(asset) ? `Asset=${asset}` : "");

  const response = await fetch(
    encodeURI(
      `${getBaseUrl()}/${apiUrl}/class/GetAvailableClasses${queryString}`
    ),
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  );

  return response.json();
}

export async function GetClasses() {
  const response = await fetch(encodeURI(`${getBaseUrl()}/${apiUrl}/class`), {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });

  return response.json();
}

export async function GetAssets(businessUnit) {
  const response = await fetch(
    encodeURI(`${getBaseUrl()}/${apiUrl}/asset?businessunit=${businessUnit}`),
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  );

  return response.json();
}

export async function GetCompanies(asset) {
  const response = await fetch(
    encodeURI(`${getBaseUrl()}/${apiUrl}/company?Asset=${asset}`),
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  );

  return response.json();
}

export async function GetDepartments(businessUnit) {
  const response = await fetch(
    encodeURI(
      `${getBaseUrl()}/${apiUrl}/department?businessunit=${businessUnit}`
    ),
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  );

  return response.json();
}

export async function GetFieldLocations(asset) {
  const response = await fetch(
    encodeURI(`${getBaseUrl()}/${apiUrl}/fieldlocation?Asset=${asset}`),
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  );

  return response.json();
}

export function PostRegistration(data) {
  const response = fetch(encodeURI(`${getBaseUrl()}/${apiUrl}/registration`), {
    method: "POST",
    headers: { "Content-Type": "application/json", "Trigger":"Preflight"},
    body: JSON.stringify(data),
  });

  return response;
}
