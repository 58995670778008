import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const SupvEmailAlaska = (props) => {
  return (
    <Form.Group>
      
      <Form.Group>
        <Row>
          <Col xs={6}>
            <Form.Label className="not-required">Supervisor Email
            </Form.Label>
          </Col>
          <Col xs={6}>
            <Form.Control
              onChange={(e) => {
                const { value } = e.target; // <-- moved outside asynchronous context to fix errors

                props.handleSupervisorEmailAlaskaChange(value);
              }}
              type="email"
              placeholder="Supervisor's email..."
              maxLength="50"
              required
            />
            <Form.Text className="text-muted">
            This email will receive an attendance report when the course is completed.
            </Form.Text>
          </Col>
        </Row>
      </Form.Group>
     </Form.Group>
  );
};

export default SupvEmailAlaska;
