export function isNotEmpty(someString) {
  return someString !== "";
}

export function hasNumber(someString) {
  return /\d/.test(someString);
}

export function hasLetters(someString) {
  var regExp = /[a-zA-Z]/g;
  return regExp.test(someString);
}

export function hasSevenOrMoreChars(someString) {
  //min phone number length
  return someString.length >= 7;
}

export function isValidName(nameString) {
  if (isNotEmpty(nameString) && !hasNumber(nameString)) {
    return true;
  } else {
    return false;
  }
}

export function isValidEmail(emailString) {
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  return emailString && pattern.test(emailString);
}

export function isValidPhoneNumber(phoneNumber) {
  if (
    isNotEmpty(phoneNumber) &&
    hasNumber(phoneNumber) &&
    !hasLetters(phoneNumber) &&
    hasSevenOrMoreChars(phoneNumber)
  ) {
    return true;
  } else {
    return false;
  }
}
