import React, { useState, useEffect } from "react";
import { GetAnnouncement } from "../../Services/mendixApiCalls";

function Announcement({ businessUnit }) {
  const [announcement, setAnnouncement] = useState("");

  useEffect(() => {
    GetAnnouncement(businessUnit).then(
      (result) => {
        setAnnouncement(result);
      },
      (error) => {
        setAnnouncement(error.message);
      }
    );
  }, [businessUnit]);

  function createAnnouncementMarkup() {
    return { __html: announcement.AnnouncementText };
  }

  function AnnouncementText() {
    return <div dangerouslySetInnerHTML={createAnnouncementMarkup()} />;
  }

  return <AnnouncementText />;
}

export default Announcement;
