import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Name = (props) => {
  return (
    <Form.Group>
      <Form.Group>
        <Row>
          <Col xs={6}>
            <Form.Label>
              <span className="required">*</span>First Name
            </Form.Label>
          </Col>
          <Col xs={6}>
            <Form.Control
              onChange={(e) => {
                const { value } = e.target; // <-- moved outside asynchronous context to fix errors

                props.handleFirstNameChange(value);
              }}
              type="text"
              placeholder="First name..."
              maxLength="15"
              required
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col xs={6}>
            <Form.Label className="not-required">Middle Initial</Form.Label>
          </Col>
          <Col xs={6}>
            <Form.Control
              onChange={(e) => {
                const { value } = e.target; // <-- moved outside asynchronous context to fix errors

                props.handleMiddleInitialChange(value);
              }}
              type="text"
              placeholder="Middle initial..."
              maxLength="1"
              required
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col xs={6}>
            <Form.Label>
              <span className="required">*</span>Last Name
            </Form.Label>
          </Col>
          <Col xs={6}>
            <Form.Control
              onChange={(e) => {
                const { value } = e.target; // <-- moved outside asynchronous context to fix errors

                props.handleLastNameChange(value);
              }}
              type="text"
              placeholder="Last name..."
              maxLength="15"
              required
            />
          </Col>
        </Row>
      </Form.Group>
    </Form.Group>
  );
};

export default Name;
