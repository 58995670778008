import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { PostRegistration } from "../../Services/mendixApiCalls";

const SubmitButtonAndModal = ({ registrationData, classChoice }) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleShowSuccessModal = () => setShowSuccessModal(true);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);

  const handleShowErrorModal = () => setShowErrorModal(true);
  const handleCloseErrorModal = () => setShowErrorModal(false);

  let history = useHistory();

  const handleFormSubmit = () => {
    PostRegistration(registrationData).then(
      (_success) => {
        if (_success.status == 200){
          handleShowSuccessModal();
        }
        else
          handleShowErrorModal();
      },
      (_error) => {
        handleShowErrorModal();
      }
    );
  };

  const registrationComplete = () => {
    handleCloseSuccessModal();
    history.push("/");
  };

  return (
    <Form.Group>
      <Button onClick={handleFormSubmit} variant="success">
        Submit Registration Request
      </Button>

      <Modal show={showSuccessModal} onHide={registrationComplete}>
        <Modal.Header closeButton>
          <Modal.Title>Registration complete</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          You've successfully registered for{" "}
          <span style={{ fontWeight: "bold" }}>{classChoice}</span>
        </Modal.Body>
        <Modal.Body>
          Keep an eye out for a confirmation email from us.
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={registrationComplete}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
        <Modal.Header closeButton>
          <Modal.Title>Registration failed</Modal.Title>
        </Modal.Header>

        <Modal.Body>Something went wrong, please try again.</Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseErrorModal}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </Form.Group>
  );
};

export default SubmitButtonAndModal;
