import React from "react";
import { Switch, Route } from "react-router-dom";
import LandingPage from "./Views/LandingPage";
import AlaskaRegistrationForm from "./Views/AlaskaRegistrationForm";
import L48RegistrationForm from "./Views/L48RegistrationForm";

function App() {
  return (
    <>
      <Switch>
        <Route path="/alaska">
          <AlaskaRegistrationForm />
        </Route>
        <Route path="/l48">
          <L48RegistrationForm />
        </Route>
        <Route path="/">
          <LandingPage />
        </Route>
      </Switch>
    </>
  );
}

export default App;
