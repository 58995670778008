import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { GetCompanies } from "../../Services/mendixApiCalls";

function ChooseCompany({ handleCompanyChange, selectedAsset, company }) {
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    setShowCompanyModal(false);
    handleCompanyChange("");
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAsset, handleCompanyChange]);

  const handleCloseModal = () => setShowCompanyModal(false);
  const handleShowModal = () => setShowCompanyModal(true);

  function getCompanies() {
    GetCompanies(selectedAsset).then(
      (result) => {
        result.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        setCompanies(result);
      },
      (error) => {
        setCompanies(error.message);
      }
    );
  }

  let companySearchValue = [];

  function handleKeyPress(event) {
    if (event.key === "Backspace") {
      companySearchValue.pop();
    } else if (event.key !== "Enter") {
      companySearchValue.push(event.key.toLowerCase());
    }

    let filteredCompanies = companies.filter((company) =>
      company.Name.toLowerCase().includes(String(companySearchValue.join("")))
    );

    if (event.key === "Enter") {
      event.preventDefault();
      setCompanies(filteredCompanies);
    }
  }

  function handleSearch() {
    let filteredCompanies = companies.filter((company) =>
      company.Name.toLowerCase().includes(String(companySearchValue.join("")))
    );
    setCompanies(filteredCompanies);
  }

  function renderCompanyName(company, index) {
    return (
      <tr onClick={() => changeCompanySelection(company.Name)} key={index}>
        <td>{company.Name}</td>
      </tr>
    );
  }

  function changeCompanySelection(selectedCompany) {
    handleCompanyChange(selectedCompany);
    handleCloseModal();
  }

  return (
    <>
      <Form.Group>
        <Row>
          <Col xs={6}>
            <Form.Label>
              <span className="required">*</span>
              <Button variant="primary" onClick={handleShowModal}>
                Company
              </Button>
            </Form.Label>
          </Col>
          <Col xs={6} className="float-right">
            <Form.Control type="text" value={company} readOnly />
          </Col>
        </Row>
      </Form.Group>

      <Modal
        size="lg"
        scrollable={true}
        dialogClassName="company-modal"
        show={showCompanyModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={6}>
              <Form.Control
                onKeyDown={handleKeyPress}
                type="text"
                placeholder="Search Companies..."
              />
            </Col>
            <Col xs={3}>
              <Button
                className="float-right"
                variant="success"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Col>
            <Col xs={3}>
              <Button className="float-right" onClick={getCompanies}>
                Reset
              </Button>
            </Col>
          </Row>

          <br />

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Company Name</th>
              </tr>
            </thead>
            <tbody>{companies.map(renderCompanyName)}</tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChooseCompany;
