import React, { useState } from "react";
import Form from "react-bootstrap/Form";

import ChooseDepartment from "../Components/RegistrationFormComponents/ChooseDepartmentL48";
import ChooseAsset from "../Components/RegistrationFormComponents/ChooseAsset";
import HasTakenSLSQuestion from "../Components/RegistrationFormComponents/HasTakenSLSQuestion";
import ChooseFieldLocation from "../Components/RegistrationFormComponents/ChooseFieldLocation";
import ChooseCompany from "../Components/RegistrationFormComponents/ChooseCompany";
import TakeSupervisorModuleQuestion from "../Components/RegistrationFormComponents/TakeSupervisorModuleQuestion";

import Name from "../Components/RegistrationFormComponents/Name";
import SupvEmailL48 from "../Components/RegistrationFormComponents/SupvEmailL48";
import Email from "../Components/RegistrationFormComponents/EmailL48";
import Phone from "../Components/RegistrationFormComponents/PhoneL48";
import Position from "../Components/RegistrationFormComponents/PositionL48";
import ChooseClass from "../Components/RegistrationFormComponents/ChooseClass";
import SubmitButtonAndModal from "../Components/RegistrationFormComponents/SubmitButtonAndModal";
import Footer from "../Components/FooterL48";

import {
  isNotEmpty,
  isValidName,
  isValidEmail,
  isValidPhoneNumber,
} from "../Services/formHelperFunctions";

import "../Styles/RegistrationForm.css";
import FormHeader from "../Components/RegistrationFormComponents/FormHeader";

function RegistrationForm() {
  const BUSINESSUNIT = "L48";
  const CLASSNAME = "Safety Leadership Seminar";
  const CLASSABBREVIATION = "SLS";

  const [department, setDepartment] = useState("");
  const [asset, setAsset] = useState("");

   const [hasTakenSLS, setHasTakenSLS] = useState();

  const [fieldLocation, setFieldLocation] = useState("");
  const [company, setCompany] = useState("");
  const [willTakeSupervisorModule, setWillTakeSupervisorModule] = useState();

  const [firstName, setFirstName] = useState("");
  const [middleInitial, setMiddleInitial] = useState("");
  const [lastName, setLastName] = useState("");
  const [supervisorEmail, setSupervisorEmail] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [position, setPosition] = useState("");
  const [classChoice, setClassChoice] = useState("");
  const [randomGuid, setRandomGuid] = useState("");

  const registrationData = {
    FirstName: firstName,
    MiddleInitial: middleInitial,
    LastName: lastName,
    SupervisorEmail: supervisorEmail,
    Email: email,
    PhoneNumber: phoneNumber,
    Position: position,
    TakingSupvModule: willTakeSupervisorModule,

    Department: { Name: department + "~" + BUSINESSUNIT } ,
    Asset: { Name: asset },
    FieldLocation: { Name: fieldLocation },
    Company: { Name: company + "~" + asset },
    Classes: { RandomGuid: randomGuid },
    Tenant: { BusinessUnit: BUSINESSUNIT },
  };

  function isValidForm() {
    if (
      isNotEmpty(department) &&
      isNotEmpty(asset) &&
      isValidName(firstName) &&
      isValidName(lastName) &&
      isValidEmail(supervisorEmail) &&
      isValidEmail(email) &&
      isValidPhoneNumber(phoneNumber) &&
      isNotEmpty(position) &&
      isNotEmpty(randomGuid)
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <div id="body">
        <Form className="registration-form">
          <FormHeader
            formName={BUSINESSUNIT + " - " + CLASSNAME}
            businessUnit={BUSINESSUNIT}
          />

          <Form.Group>
            <Form.Label style={{ fontWeight: "bold", marginTop: "15px" }}>
              Please answer the following questions so we can assist you in
              locating the appropriate {CLASSABBREVIATION} Class.
            </Form.Label>
            <Form.Group>
              <ChooseDepartment
                department={department}
                handleDepartmentChange={setDepartment}
                businessUnit={BUSINESSUNIT}
              />
              <ChooseAsset
                businessUnit={BUSINESSUNIT}
                asset={asset}
                handleAssetChange={setAsset}
              />
            </Form.Group>
            <Form.Group>             
              {isNotEmpty(asset) &&
                isNotEmpty(department) && (
                  <HasTakenSLSQuestion
                    hasTakenSLS={hasTakenSLS}
                    handleHasTakenSLS={setHasTakenSLS}
                  />
                )}
            </Form.Group>
            <br />
            <Form.Group>
              {isNotEmpty(asset) &&
                isNotEmpty(department) &&
                hasTakenSLS === false && (
                  <>
                    <ChooseFieldLocation
                      asset={asset}
                      fieldLocation={fieldLocation}
                      handleFieldLocationChange={setFieldLocation}
                    />
                    <ChooseCompany
                      selectedAsset={asset}
                      company={company}
                      handleCompanyChange={setCompany}
                    />
                    <TakeSupervisorModuleQuestion
                      handleWillTakeSupervisorModuleChange={
                        setWillTakeSupervisorModule
                      }
                    />
                  </>
                )}
            </Form.Group>
            <br />
            <Form.Group>
              {isNotEmpty(asset) &&
                isNotEmpty(department) &&
                hasTakenSLS === false &&
                isNotEmpty(fieldLocation) &&
                isNotEmpty(company) &&
                willTakeSupervisorModule !== undefined && (
                  <>
                     <Name
                     handleFirstNameChange={setFirstName}
                     handleMiddleInitialChange={setMiddleInitial}
                     handleLastNameChange={setLastName}
                    />  
                    <SupvEmailL48
                      handleSupervisorEmailL48Change={setSupervisorEmail}
                    />
                    <Email
                      handleEmailChange={setEmail}                      
                    />
                   
                     <Phone                                                                 
                      handlePhoneNumberChange={setPhoneNumber}                      
                    />
                     <Position
                      handlePositionChange={setPosition}
                    />
                    <br /> <br />
                    <ChooseClass
                      selectedAsset={asset}
                      selectedFieldLocation={fieldLocation}
                      selectedClass={classChoice}
                      handleClassChange={setClassChoice}
                      handleRandomGuidChange={setRandomGuid}
                    />
                  </>
                )}
            </Form.Group>
            <br />
            <Form.Group>
              {isValidForm() && (
                <SubmitButtonAndModal
                  registrationData={registrationData}
                  classChoice={classChoice}
                />
              )}
            </Form.Group>
          </Form.Group>
        </Form>
      </div>
      <Footer />
    </>
  );
}

export default RegistrationForm;
