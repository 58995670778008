import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { GetAvailableClasses } from "../../Services/mendixApiCalls";

function ChooseClass({
  selectedAsset,
  selectedClass,
  selectedFieldLocation,
  handleClassChange,
  handleRandomGuidChange,
}) {
  const [availableClasses, setAvailableClasses] = useState([]);

  useEffect(() => {
    handleRandomGuidChange("");
    handleClassChange("");

    GetAvailableClasses(selectedAsset, selectedFieldLocation).then(
      (result) => {
        result = result.sort(function (a, b) {
          return new Date(a.ClassDate) - new Date(b.ClassDate);
        });
        setAvailableClasses(result);
      },
      (error) => {
        setAvailableClasses(error.message);
      }
    );
  }, [
    selectedAsset,
    selectedFieldLocation,
    handleRandomGuidChange,
    handleClassChange,
  ]);

  const changeClassSelection = (className, classRandomGuid) => {
    handleClassChange(className);
    handleRandomGuidChange(classRandomGuid);
  };

  function AvailableClasses() {
    const hasAvailableClasses =
      availableClasses !== "Failed to fetch." && availableClasses.length > 0;

    const NoAvailableClassesFound = () => {
      return <Dropdown.Item href="#">No available classes found</Dropdown.Item>;
    };

    const AvailableClassItems = () => {
      return (
        <>
          {availableClasses.map((c) => (
            <Dropdown.Item
              onClick={() =>
                changeClassSelection(c.ClassRegistrationNameLabel, c.RandomGuid)
              }
              key={c.ClassRegistrationNameLabel}
              href="#"
            >
              {c.ClassRegistrationNameLabel}
            </Dropdown.Item>
          ))}
        </>
      );
    };

    return (
      <DropdownButton
        variant={hasAvailableClasses ? "outline-primary" : "outline-warning"}
        id="class-dropdown"
        title={selectedClass}
      >
        {hasAvailableClasses ? (
          <AvailableClassItems />
        ) : (
          <NoAvailableClassesFound />
        )}
      </DropdownButton>
    );
  }

  return (
    <Form.Group>
      <Form.Group>
        <Row>
          <Col xs={6}>
            <Form.Label>
              <span className="required">*</span>Select Class
            </Form.Label>
          </Col>
          <Col xs={6}>
            <AvailableClasses />
          </Col>
        </Row>
      </Form.Group>
    </Form.Group>
  );
}

export default ChooseClass;
