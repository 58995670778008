import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Phone = (props) => {
  return (
    <Form.Group>    
      <Form.Group>
        <Row>
          <Col xs={6}>
            <Form.Label>
            <span className="required">*</span>Phone Number
            </Form.Label>
          </Col>
          <Col xs={6}>
            <Form.Control
              onChange={(e) => {
                const { value } = e.target; // <-- moved outside asynchronous context to fix errors

                props.handlePhoneNumberChange(value);
              }}
              type="string"
              placeholder="Your phone number..."
              maxLength="20"
            />
            <Form.Text className="text-muted">
              Used for additional verification – not shared with anyone.
            </Form.Text>
          </Col>
        </Row>
      </Form.Group>
    </Form.Group>
  );
};

export default Phone;
