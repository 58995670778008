import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { GetAssets } from "../../Services/mendixApiCalls";

function ChooseAsset({
  businessUnit,
  asset,
  handleAssetChange,
  selectedAssetEmailAddress,
}) {
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    handleAssetChange("");

    GetAssets(businessUnit).then(
      (result) => {
        result.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        setAssets(result);
      },
      (error) => {
        setAssets(error.message);
      }
    );
  }, [businessUnit, handleAssetChange]);

  function changeAssetSelection(selectedAssetName, selectedAssetEmailAddress) {
    handleAssetChange(selectedAssetName);

    if (selectedAssetEmailAddress) {
      selectedAssetEmailAddress(selectedAssetEmailAddress);
    }
  }

  function Assets() {
    const hasAssets = assets !== "Failed to fetch." && assets.length > 0;

    const NoLocationsFound = () => {
      return <Dropdown.Item href="#">No assets found</Dropdown.Item>;
    };

    const AssetItems = () => {
      return (
        <>
          {assets.map((asset) => (
            <Dropdown.Item
              onClick={() => changeAssetSelection(asset.Name)}
              key={asset.Name}
              href="#"
            >
              {asset.Name}
            </Dropdown.Item>
          ))}
        </>
      );
    };

    return (
      <DropdownButton
        variant={hasAssets ? "outline-primary" : "outline-warning"}
        id="asset-dropdown"
        title={asset}
      >
        {hasAssets ? <AssetItems /> : <NoLocationsFound />}
      </DropdownButton>
    );
  }

  return (
    <Form.Group>
      <Row>
        <Col xs={6}>
          <Form.Label>
            <span className="required">*</span>Asset
          </Form.Label>
        </Col>
        <Col xs={6}>
          <Assets />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default ChooseAsset;
