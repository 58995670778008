import React from "react";
import { Link, Redirect } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";

import "../Styles/index.css";

function Titles() {
  return (
    <Row>
      <Col>
        <h3
          style={{
            color: "darkblue",
            marginBottom: "15px",
            textAlign: "center",
          }}
        >
          L48 - Safety Leadership Seminar
        </h3>
      </Col>
      <Col>
        <h3
          style={{
            color: "darkblue",
            marginBottom: "15px",
            textAlign: "center",
          }}
        >
          Alaska Workshops
        </h3>
      </Col>
    </Row>
  );
}

function Images() {
  return (
    <>
      <Row>
        <Col>
          <Image
            src={require("../Images/SafetyMessage.png")}
            alt="ConocoPhillips Safety Message"
            style={{ maxHeight: "400px" }}
            fluid
          />
        </Col>
        <Col>
          <Image
            src={require("../Images/LifeSavingRules.png")}
            alt="ConocoPhillips Life Saving Rules"
            style={{ maxHeight: "400px", display: "flex", marginTop: "5%" }}
            fluid
          />
        </Col>
      </Row>
    </>
  );
}

function RegistrationButtons() {
  return (
    <>
      <Row>
        <Col>
          <Link to="/l48" style={{ textDecoration: "none" }}>
            <Button id="centerButton" variant="success">
              Register for L48
            </Button>
          </Link>
        </Col>
        <Col>
          <Link to="/alaska" style={{ textDecoration: "none" }}>
            <Button id="centerButton" variant="success">
              Register for Alaska
            </Button>
          </Link>
        </Col>
      </Row>
    </>
  );
}

function LandingPage() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let bu = params.get('bu');  

  if (bu == 'l48') {return(<Redirect to="/l48" />);} 
  else if (bu == 'alaska') {return(<Redirect to="/alaska" />);} 
    else {
    return (
      <div id="body">
        <div className="landingPage">
          <Titles />
          <br /> <hr />
          <RegistrationButtons />
          <br /> <hr />
          <Images />
          <br /> <hr />
        </div>
  
      </div>
    );
  }

  
}

export default LandingPage;
