import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function HasTakenSLSQuestion(props) {
  function handleClick(e) {
    if (e.target.id === "hasTakenEmployeeSLS") {
      props.handleHasTakenSLS(true);
    } else {
      props.handleHasTakenSLS(false);
    }
  }

  return (
    <Form.Group>
      <Row>
        <Col xs={6}>
          <Form.Label inline="true">
            Have you taken the SLS class within the past 3 years?
          </Form.Label>
        </Col>
        <Col xs={6}>
          <Form.Check
            onChange={handleClick}
            inline="true"
            label="yes"
            type="radio"
            name="employeeSLSTakenRadios"
            id="hasTakenEmployeeSLS"
          />
          <Form.Check
            onChange={handleClick}
            inline="true"
            label="no"
            type="radio"
            name="employeeSLSTakenRadios"
            id="hasNotTakenEmployeeSLS"
          />
        </Col>
      </Row>

      {props.hasTakenSLS === true && (
        <p style={{ color: "green", fontWeight: "bold" }}>
          <span role="img" aria-label="green checkmark">
            ✔️
          </span>{" "}
          The Safety Leadership Seminar is valid for 3 consecutive years.
        </p>
      )}
    </Form.Group>
  );
}

export default HasTakenSLSQuestion;
