import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const BBSGroupNumberField = (props) => {
  return (
    <>
      <Form.Group>
        <Row>
          <Col xs={6}>
            <Form.Label className="not-required">BBS Group Number</Form.Label>
          </Col>
          <Col xs={6}>
            <Form.Control
              onChange={(e) => {
                const { value } = e.target; // <-- moved outside asynchronous context to fix errors
              }}
              type="text"
              placeholder="Group number..."
              maxLength="15"
              required
            />
          </Col>
        </Row>
      </Form.Group>
    </>
  );
};

export default BBSGroupNumberField;
